import React from 'react'
import { withTranslation } from 'react-i18next'

const Contacts = ({ t, i18n: { language } }) => {
    const email = language === 'en' ? 'info@rashidov.eu' : 'info@rashidov.su'
    const waNumber = language === 'en' ? '+37251918691' : '+79207858492'
    return (<section className='section call' id='section-call'>
        <div className='container'>
            <div className='row'>
                <div className='col-12 col-md-12 col-lg-5 offset-lg-1'>
                    <h2 className='h2'>
                        { t('landing.contacts.title') }
                    </h2>
                </div>
                <div className='col-12 col-md-12 col-lg-5'>
                    <div className='call-list_container'>
                        {/* <div
                            className='call-list__item call-list__item_order'>{ `${ t('landing.words.order') }:` }</div> */}
                        <div className='call-list'>
                            <div className='call-list__item'>
                                { language === 'en' ?
                                    null :
                                    <a href='tel://+78422505504'>
                                        <img src='/img/phone-ico.png' alt='Ico' />
                                        <span>+7 (842) 250-55-04</span>
                                    </a> }
                            </div>
                            <div className='call-list__item'>
                                <a href={ `https://wa.me/${waNumber}` }><img src='/img/whatsapp.png' alt='Ico' /></a>
                                <a href='https://t.me/rashidovscale'><img src='/img/telegram.png' alt='Ico' /></a>
                            </div>
                            <div className='call-list__item'>
                                <a href={ `mailto:${ email }` }>
                                    <img src='/img/email-ico.png' alt='Ico' />
                                    <span>{ email }</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default withTranslation()(Contacts)
